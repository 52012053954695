import React, { useState } from "react";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import Layout from "../../layouts/default";
import Logo from "../../components/logo";
import Cotacao from "../../components/cotacao";

import { useAuth } from "../../context/AuthContext";

import { Link } from "react-router-dom";

export default function Main() {
  const [contatoOpen, setContatoOpen] = useState(false);

  const { signOut } = useAuth();

  const handleClose = () => {
    setContatoOpen(false);
  };

  return (
    <Layout>
      <Dialog
        open={contatoOpen}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Atendimento</DialogTitle>
        <DialogContent sx={{ minWidth: 300 }}>
          {/* <DialogContentText id="scroll-dialog-description"> */}
          <DialogContentText>
            <strong>Horário de Atendimento</strong>
            <br />
            Seg. à Sex: 9:00 às 17:00
            <br />
            Sáb.: 9:00 às 12:00
          </DialogContentText>
          <DialogContentText sx={{ mt: 1 }}>
            <strong>Dúvidas</strong>
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://wa.me/5594991903598"
            >
              (94) 99190-3598
            </a>
          </DialogContentText>
          <DialogContentText sx={{ mt: 1 }}>
            <strong>Fechamento de Serviços</strong>
            <br />
            Fazer o pagamento conforme o valor do dia.
          </DialogContentText>
          <DialogContentText sx={{ mt: 1 }}>
            <strong>Orçamentos</strong>
            <br /> Responderemos por ordem de chegada, sem previsão de tempo de
            resposta
          </DialogContentText>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <Grid sx={{ pt: 8, pb: 8 }}>
        <Logo />
      </Grid>
      <Cotacao />

      <Box noValidate sx={{ pl: 2, pr: 2 }}>
        {/* <Button
          component={Link}
          to="/pedido/novo"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Fazer Novo Pedido Aliança
        </Button> */}
        {/* <Button
          component={Link}
          to="/pedido/todos"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Acompanhar Pedidos
        </Button> */}

        <Button
          href="/criador"
          fullWidth
          // disabled
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2 }}
        >
          Criador 3D
        </Button>
        <Button
          href="/calculadora-teor"
          fullWidth
          // disabled
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Calculadora de Teor
        </Button>
        <Button
          component={Link}
          to="/orcamento"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Precificador de Alianças
        </Button>
        <Button
          fullWidth
          disabled
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2 }}
        >
          Precificador de Joias (em breve)
        </Button>
        <Button
          component={Link}
          to="/orcamento-diamante"
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Calculadora Orçamento Diamante
        </Button>
        <Button
          fullWidth
          disabled
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2 }}
        >
          Catálogo STL (em breve)
        </Button>
        <Button
          component={Link}
          to="/orcamento-joias"
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2 }}
        >
          Orçamento Joias
        </Button>
        <Button
          onClick={() => setContatoOpen(true)}
          fullWidth
          variant="outlined"
          sx={{ mt: 2, mb: 2 }}
          size="small"
        >
          Atendimento
        </Button>

        <Button onClick={() => signOut()} fullWidth sx={{ mt: 2, mb: 2 }}>
          Sair
        </Button>
      </Box>
    </Layout>
  );
}
