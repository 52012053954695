import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { PrivateRoute, PublicRoute } from "./PrivateRoute";

import Main from "../pages/main";
// import Category from "../pages/Category";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import PedidoNovo from "../pages/pedido/novo";
import RecuperarSenha from "../pages/auth/recuperar";
import NovaSenha from "../pages/auth/recuperar/nova";
import Teor from "../pages/teor";
import Criador from "../pages/criador";

import PedidoLista from "../pages/pedido/lista";
import PedidoDetalhe from "../pages/pedido/detalhe";
import PedidoFinalizados from "../pages/pedido/lista/finalizados";
import PedidoProducao from "../pages/pedido/producao";

import Orcamento from "../pages/orcamento";
import OrcamentoDiamante from "../pages/orcamendoDiamante";
import OrcamentoJoias from "../pages/orcamendoJoias";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/criador" element={<Criador />} />
          <Route
            exact
            path="/calculadora-teor"
            element={<Teor voltar={true} />}
          />
          <Route exact path="/pedido/novo" element={<PedidoNovo />} />
          <Route exact path="/pedido/todos" element={<PedidoLista />} />
          <Route exact path="/pedido/producao" element={<PedidoProducao />} />
          <Route exact path="/orcamento" element={<Orcamento />} />
          <Route
            exact
            path="/orcamento-diamante"
            element={<OrcamentoDiamante />}
          />
          <Route exact path="/orcamento-joias" element={<OrcamentoJoias />} />
          <Route
            exact
            path="/pedido/finalizados"
            element={<PedidoFinalizados />}
          />
          <Route exact path="/pedido/:id" element={<PedidoDetalhe />} />
        </Route>

        <Route exact path="/" element={<PublicRoute />}>
          <Route exact path="/teor" element={<Teor />} />
          <Route exact path="/auth/login" element={<Login />} />
          <Route exact path="/auth/cadastrar" element={<Register />} />
          <Route exact path="/auth/nova-senha" element={<NovaSenha />} />
          <Route
            exact
            path="/auth/recuperar-senha"
            element={<RecuperarSenha />}
          />
        </Route>

        {/* <Route exact path="/recovery-password" element={<RecoveryPassword/>}/> */}
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
    </Router>
  );
};

export default App;

// export default function RouteList() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" exact component={Main} />

//         <Route path="/auth/login" exact component={Login} />

//         <Route path="/nobrand" exact component={() => <h1>Error 404</h1>} />

//         <Route path="/" component={() => <h1>Error 404</h1>} />
//       </Routes>
//     </BrowserRouter>
//   );
// }
