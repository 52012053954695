import { useState, useEffect } from "react";
// import * as qs from "qs";
// import { addDays, format } from "date-fns";

import Grid from "@mui/material/Grid";

import axios from "axios";
import Layout from "../../layouts/default";
// import api from "../../services/api";

import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Logo from "../../components/logo";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//Custos
const preco_ouro_mil_compra = 475 * 0.89;
const preco_ouro_mil = 520;
const preco_ouro_18k = preco_ouro_mil * 0.76;
const preco_impressao = 200; //ml
const quebra_producao = 0.1; //10%
const preco_fundicao = 30; //40 por grama
const preco_acabamento = 55; //55 por grama
const preco_ouro_18k_compra = preco_ouro_mil_compra * 0.69;
const preco_cravacao = 10; //por pedra
const preco_embalagem = 20;

const schema = Yup.object({
  tipos_joia: Yup.number().required("Tipo de Joia é Obrigatório"),
  peso_final: Yup.number().required("Peso é Obrigatório"),
  forma_pagamento: Yup.number().required("Peso é Obrigatório"),
  metal_cliente: Yup.number()
    .transform((value, originalValue) => {
      // Verifica se o valor original é uma string antes de chamar .trim()
      if (typeof originalValue === "string") {
        return originalValue.trim() === "" ? undefined : Number(originalValue);
      }
      return value; // Retorna o número diretamente se não for string
    })
    .test(
      "metal-cliente-valido",
      "O metal do cliente não pode ser maior que o peso total permitido.",
      function (value) {
        const { peso_final } = this.parent; // Acessa o valor de peso_final
        if (value === undefined || value === null) return true; // Se não for preenchido, é válido
        const pesoTotalPermitido = peso_final / (1 - quebra_producao);
        return value <= pesoTotalPermitido; // Valida se está dentro do limite
      }
    ),
});

export default function OrcamentoJoias() {
  const [metalCliente, setMetalCliente] = useState(false);
  const [valorVenda, setValorVenda] = useState(0);
  const [pesoTotal, setPesoTotal] = useState(0);
  const [pesoMetalCliente, setPesoMetalCliente] = useState(0);
  const [valorAuCliente, setValorAuCliente] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState([]);
  const [imposto, setImposto] = useState(0);

  useEffect(() => {
    axios
      .get("https://economia.awesomeapi.com.br/json/last/USD-BRL")
      .then((response) => {
        // console.log(response.data.USDBRL.ask);
        // setPrecoDolar();
        setValue("preco_ouro", Number(response.data.USDBRL.ask).toFixed(2));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formasDePagamento = [
    {
      id: 1,
      nome: "PIX",
      juros: 0,
      parc: 1,
    },
    {
      id: 2,
      nome: "Débido",
      juros: 2.9,
      parc: 1,
    },
    {
      id: 3,
      nome: "Crédito 1x",
      juros: 4.32,
      parc: 1,
    },
    {
      id: 4,
      nome: "Crédito 2x",
      juros: 8.99,
      parc: 2,
    },
    {
      id: 5,
      nome: "Crédito 3x",
      juros: 10.99,
      parc: 3,
    },
    {
      id: 6,
      nome: "Crédito 4x",
      juros: 11.99,
      parc: 4,
    },
    {
      id: 7,
      nome: "Crédito 5x",
      juros: 12.99,
      parc: 5,
    },
    {
      id: 8,
      nome: "Crédito 6x",
      juros: 13.99,
      parc: 6,
    },
    {
      id: 9,
      nome: "Crédito 7x",
      juros: 14.99,
      parc: 7,
    },
    {
      id: 10,
      nome: "Crédito 8x",
      juros: 15.99,
      parc: 8,
    },
    {
      id: 11,
      nome: "Crédito 9x",
      juros: 16.99,
      parc: 9,
    },
    {
      id: 12,
      nome: "Crédito 10x",
      juros: 17.99,
      parc: 10,
    },
    {
      id: 13,
      nome: "Crédito 11x",
      juros: 17.99,
      parc: 11,
    },
    {
      id: 14,
      nome: "Crédito 12x",
      juros: 18.99,
      parc: 12,
    },
  ];

  const tiposJoia = [
    {
      ct: 1,
      desc: "Joia de Pano",
    },
    {
      ct: 2,
      desc: "Joias com Impressão 3D",
    },
    {
      ct: 3,
      desc: "Alianças Tradicionais",
    },
    {
      ct: 4,
      desc: "Joias Manuais",
    },
    {
      ct: 5,
      desc: "Joias no Laser",
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      quantidade_pedras: 0,
    },
  });

  // const watchMedidaPedras = watch("tipos_joia");
  const watchPesoFinal = watch("peso_final");

  useEffect(() => {
    if (watchPesoFinal) {
      const novoPesoTotal = watchPesoFinal / (1 - quebra_producao);
      setPesoTotal(novoPesoTotal);
    }
  }, [watchPesoFinal]);

  const handleOnSubmit = (data) => {
    console.log(data);
    //Calcular os custos
    const custo_desenho = 150;
    const custo_impressao = data.peso_final * 0.07 * preco_impressao;
    const custo_fundicao = Math.max(data.peso_final * preco_fundicao, 75);
    const peso_ouro_producao = Number(data.peso_final) / (1 - quebra_producao); //Total de Ouro Necessário
    const peso_ouro_total =
      peso_ouro_producao - Number(data.metal_cliente || 0); //Total de Ouro Necessário descontando o do CLiente
    const custo_ouro = peso_ouro_total * preco_ouro_18k;
    console.log(peso_ouro_total);
    const custo_acabamento = data.peso_final * preco_acabamento;
    const custo_cravacao = data.quantidade_pedras * preco_cravacao;

    const valor_cliente_au =
      preco_ouro_18k_compra * Number(data.metal_cliente || 0); //Valor que o cliente tem em ouro
    setValorAuCliente(valor_cliente_au);
    setPesoMetalCliente(data.metal_cliente || 0);

    let custoTotalVenda = 0;

    //Joia de Pano
    if (data.tipos_joia === 1) {
      const preco_compra =
        preco_ouro_mil * 1.3 * data.peso_final - valor_cliente_au;

      custoTotalVenda += preco_compra;
    } else if (data.tipos_joia === 2) {
      //Joias com Impressão 3D
      custoTotalVenda +=
        custo_desenho +
        custo_impressao +
        custo_fundicao +
        custo_ouro +
        custo_acabamento +
        custo_cravacao;
    } else if (data.tipos_joia === 3) {
      //Alianças Tradicionais
      custoTotalVenda += 50; //Fundição
      custoTotalVenda += custo_ouro; //Materia Prima
      custoTotalVenda += custo_acabamento; //Acabamento
      custoTotalVenda += custo_cravacao; //Cravação
    } else if (data.tipos_joia === 4) {
      //Joias Manuais
      custoTotalVenda += custo_ouro; //Materia Prima
      custoTotalVenda += custo_acabamento; //Acabamento
      custoTotalVenda += custo_cravacao; //Cravação
    } else if (data.tipos_joia === 5) {
      //Joias Laser
      custoTotalVenda += 50; //Desenho
      custoTotalVenda += 50; //Corte
      custoTotalVenda += custo_ouro * 1.08; //Materia Prima (adicional de 8% quebra)
      custoTotalVenda += custo_acabamento; //Acabamento
      custoTotalVenda += custo_cravacao; //Cravação
    }

    //Adiciona Custo Embalagem
    custoTotalVenda += preco_embalagem;

    //Calcular Lucro
    custoTotalVenda = custoTotalVenda / (1 - 0.1); //10%

    //Calcular Taxa de Cartão se existir
    const pagamentoSelecionado = formasDePagamento.find(
      (pagamento) => pagamento.id === data.forma_pagamento
    );
    setFormaPagamento(pagamentoSelecionado);

    setImposto(data.imposto);
    //Taxa Total
    const taxaTotal = data.imposto / 100 + pagamentoSelecionado.juros / 100; //Total de taxa em %

    setSubTotal(custoTotalVenda);
    custoTotalVenda = (custoTotalVenda * 1) / (1 - taxaTotal);

    // console.log("Taxa:", taxaTotal);
    // //Salvar Subtotal
    // setSubTotal(custoTotalVenda);

    // //Calcular Imposto
    // const Imposto = custoTotalVenda / (1 - imposto);

    // console.log(custoTotalVenda);

    setValorVenda(custoTotalVenda.toFixed(2));
  };

  const handleMetalClienteChange = (event) => {
    setValue("metal_cliente", 0);
    setMetalCliente(event.target.checked);
  };

  useEffect(() => {
    //Dados Iniciais
    setValue("quantidade_pedras", 0);
    setValue("imposto", 8);
  }, [setValue]);

  const copiarConteudo = (conteudo) => {
    // Copiar para a área de transferência
    navigator.clipboard.writeText(conteudo).then(() => {
      alert("Conteúdo copiado!");
    });
  };

  return (
    <Layout>
      <AppBar position="static" color="primary" enableColorOnDark />
      <Grid sx={{ pt: 4, pb: 4 }}>
        <Logo />
      </Grid>
      {/* <Cotacao click={(value) => changePrice(value)} /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
        sx={{ mt: 1, maxWidth: 300, width: "100%" }}
      >
        <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
          Orçamento de Joias
        </Typography>
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
          Preencha os dados para ter um orçamento!
        </Typography>
        {/* <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Ouro Hoje"
            fullWidth
            id="preco_ouro"
            name="preco_ouro"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            error={errors.preco_ouro ? true : false}
            {...register("preco_ouro")}
          />
        </FormControl> */}
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.tipos_joia ? true : false}
        >
          <InputLabel id="tipos_joia_label">Tipo de Joia</InputLabel>
          <Controller
            name="tipos_joia"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId="tipos_joia_label"
                id="tipos_joia"
                label="Tipo de Joia"
                {...register("tipos_joia")}
              >
                {tiposJoia.map((item, id) => (
                  <MenuItem key={id} value={item.ct}>
                    {item.desc}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          {errors && errors.tipos_joia && errors.tipos_joia.message && (
            <FormHelperText>Selecione o Tipo</FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          variant="outlined"
          error={errors.peso_final ? true : false}
        >
          <TextField
            type="number"
            label="Peso Final"
            fullWidth
            id="peso_final"
            name="peso_final"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">g</InputAdornment>,
            }}
            inputProps={{
              min: 0, // Define o valor mínimo
            }}
            error={errors.peso_final ? true : false}
            {...register("peso_final")}
          />
          {errors && errors.peso_final && errors.peso_final.message && (
            <FormHelperText>Qual o peso?</FormHelperText>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={metalCliente}
                onChange={handleMetalClienteChange}
              />
            }
            label={"Metal Cliente. Max: " + pesoTotal.toFixed(2) + "g"}
          />
        </FormControl>
        {metalCliente === true && (
          <FormControl
            fullWidth
            sx={{ mb: 3 }}
            variant="outlined"
            error={errors.metal_cliente ? true : false}
          >
            <TextField
              type="number"
              label="Metal Cliente"
              fullWidth
              id="metal_cliente"
              name="metal_cliente"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">g</InputAdornment>
                ),
              }}
              inputProps={{
                min: 0, // Define o valor mínimo
              }}
              error={errors.metal_cliente ? true : false}
              {...register("metal_cliente")}
            />
            {errors && errors.metal_cliente && errors.metal_cliente.message && (
              <FormHelperText>{errors.metal_cliente.message}</FormHelperText>
            )}
          </FormControl>
        )}
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Qtd Pedras"
            fullWidth
            id="quantidade_pedras"
            name="quantidade_pedras"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0, // Define o valor mínimo
            }}
            error={errors.quantidade_pedras ? true : false}
            {...register("quantidade_pedras")}
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.forma_pagamento ? true : false}
        >
          <InputLabel id="forma_pagamento_label">Forma de Pagamento</InputLabel>
          <Controller
            name="forma_pagamento"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId="forma_pagamento_label"
                id="forma_pagamento"
                label="Forma de Pagamento"
                {...register("forma_pagamento")}
              >
                {formasDePagamento.map((item, id) => (
                  <MenuItem key={id} value={item.id}>
                    {item.nome}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          {errors &&
            errors.tiposforma_pagamento_joia &&
            errors.forma_pagamento.message && (
              <FormHelperText>Selecione o Tipo</FormHelperText>
            )}
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          variant="outlined"
          error={errors.imposto ? true : false}
        >
          <TextField
            type="number"
            label="Imposto"
            fullWidth
            id="imposto"
            name="imposto"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            inputProps={{
              min: 0, // Define o valor mínimo
            }}
            error={errors.imposto ? true : false}
            {...register("imposto")}
          />
          {errors && errors.imposto && errors.imposto.message && (
            <FormHelperText>{errors.imposto.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Button variant="contained" size="medium" type="submit">
            Calcular
          </Button>
          <p />
          <Button component={Link} to="/" autoFocus>
            Voltar
          </Button>
        </FormControl>
      </Box>
      {valorVenda > 0 && (
        <Box style={{ fontSize: 12 }}>
          <strong>Formação do Preço</strong>
          <br />
          {/* <strong>Preço Dolar:</strong> R$ {precoDolar ? precoDolar : ""} */}
          <br />
          <strong>SubTotal:</strong> R${" "}
          {subTotal ? subTotal.toFixed(2) : "0,00"}
          <br />
          <strong>Imposto ({imposto}%):</strong> R${" "}
          {(valorVenda * (imposto / 100)).toFixed(2)}
          <br />
          <strong>Taxa Cartão ({formaPagamento.juros}%):</strong> R${" "}
          {(valorVenda * (formaPagamento.juros / 100)).toFixed(2)}
          <br />
          <div style={{ fontSize: 20, textAlign: "center" }}>
            <strong>Total: </strong>R$ {valorVenda ? valorVenda : "0,00"}
            <br />
            {formaPagamento.parc > 1 && (
              <strong>
                {formaPagamento.parc}x de R${" "}
                {(valorVenda / formaPagamento.parc).toFixed(2)}
              </strong>
            )}
          </div>
          <br />
          <button
            onClick={() =>
              copiarConteudo(
                `Total: R$ ${valorVenda ? valorVenda : "0,00"}\n${
                  formaPagamento.parc
                }x de R$ ${(valorVenda / formaPagamento.parc).toFixed(2)}`
              )
            }
            style={{ marginTop: 10, position: "center" }}
          >
            Copiar Conteúdo
          </button>
          <br />
          ---------------------------------------------
          <br />
          <strong>Au Cliente ({pesoMetalCliente || 0}g):</strong> R${" "}
          {valorAuCliente.toFixed(2)}
          <br />
          <strong>
            Custo Materia Prima ({pesoTotal.toFixed(2) || 0}g):
          </strong>{" "}
          R$ {(pesoTotal * preco_ouro_18k).toFixed(2)}
        </Box>
      )}
    </Layout>
  );
}
